import React from 'react';
import { ConfigProvider } from 'antd';
import SideNavbar from '../SideNavbar/SideNavbar';

export default function ContentWrapper({ children }) {
  return (
    <ConfigProvider>
      <div style={{ display: 'flex', height: '100vh', width: '100%' }}>
        <SideNavbar />
        <div style={{ flex: '1', overflowY: 'auto', padding: '4rem' }}>
          {children}
        </div>
      </div>
    </ConfigProvider>
  );
}

