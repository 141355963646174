import axios from '../config/axios.config';
import { openNotificationWithIcon } from '../constants/constant';

const UserService = {
  login: async (credentials) => {
    return await axios.post(`/admin/login`, credentials);
  },
  fetchAllUsers: async (page, limit, search) => {
    try {
      const response = await axios.get('/admin/users', {
        params: { page, limit, search },
      });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch dashboard data:', error);
      throw error;
    }
  },

  inviteUser: async (data) => {
    try {
      const response = await axios.post('/admin/add-user', data);
      return response.data;
    } catch (error) {
      if (error.response.status === 409) {
        openNotificationWithIcon('error', 'Error', 'User with email already exists');
      }
      else {
        openNotificationWithIcon('error', 'Error', 'Failed to invite user');
      }
    }
  },

  deleteUser: async (id) => {
    try {
      const response = await axios.delete(`/admin/user/${id}`);
      return response.data;
    } catch (error) {
      console.error('Failed to delete dashboard:', error);
      throw error;
    }
  },
  updatePassword: async (data) => {
    try {
      const response = await axios.put(`/admin/update-password`, data);
      return response;
    } catch (error) {
      if (error.response.status === 409) {
        console.log("Error here", error)
        openNotificationWithIcon('error', 'Error', 'New password must be different from current password');
      }
      else{
        openNotificationWithIcon('error', 'Error', 'Failed to update password');
      }
    }
  },

  forgotPassword: async (email) => {
    return await axios.post(`/admin/forgot-password`, email);
  },

  resetPassword: async (data, token) => {
    return await axios.post(`/admin/reset-password?token=${token}`, data);
  },

  verifyEmail: async (queryParams, data) => {
    try {
      const response = await axios.post('/admin/invite-user', data, {
        params: queryParams,
      });
      return response.data;
    } catch (error) {
      console.error('Failed to verify email:', error);
      throw error;
    }
  },
};


export default UserService;
