import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import styles from "./styles.module.css";
import UserService from "../../services/user-actions.service";
import { openNotificationWithIcon } from "../../constants/constant";

function Forgetpassword() {
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    setLoading(true);
    try {
      await UserService.forgotPassword(values);

      openNotificationWithIcon(
        "success",
        "Success",
        "Password reset link sent successfully to your registered mail"
      );
    } catch (error) {
      if (error.response && error.response.status === 404) {
        openNotificationWithIcon("error", "Error", "User not found");
      } else {
        console.error(error);
        openNotificationWithIcon(
          "error",
          "Error",
          "An error occurred while resetting the password"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className={styles.login}>
      <div className={styles.form_container}>
        <div className={styles.right}>
          <h2 className={styles.from_heading}>Looker Embed Portal - Forgot Password</h2>

          <Form
            name="basic"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24, style: { marginTop: "-10px" } }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 5, span: 26 }}>
              <Button type="primary" htmlType="submit" loading={loading}>
                  Send Email
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Forgetpassword;
