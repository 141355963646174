import React from 'react';
import { ApiOutlined, DashboardOutlined, UserOutlined, LogoutOutlined, FileProtectOutlined } from '@ant-design/icons';
import { Menu, Divider, Avatar } from 'antd';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import styles from '../SideNavbar/siebar.module.css';

const SideNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userName = localStorage.getItem('userName');
  const isAdmin = localStorage.getItem('admin') === 'true';

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userName');
    localStorage.removeItem('admin');
    navigate('/login');
  };

  const menuItems = [
    {
      key: '/dashboard',
      icon: <DashboardOutlined />,
      label: <Link to="/dashboard">Dashboard</Link>,
    },
    ...(isAdmin
    ? [{key: '/user',
      icon: <UserOutlined />,
      label: <Link to="/user">User</Link>,}]
      : []
    ),
    {
      key: '/report',
      icon: <FileProtectOutlined />,
      label: <Link to="/report">Report</Link>,
    },
    {
          key: '/apikeys',
          icon: <ApiOutlined />,
          label: <Link to="/apikeys">Api Keys</Link>,
        }
  ];

  return (
    <div className={styles.sidebar}>
      <div className={styles.userProfile}>
        <Avatar size={64} icon={<UserOutlined />} />
        <div className={styles.userName}>{userName}</div>
      </div>
      <Divider />
      <Menu
        items={menuItems}
        selectedKeys={[location.pathname]}
        inlineIndent={30}
        className={styles.menu}
      />
      <div className={styles.logoutContainer}>
        <Menu
          items={[
            {
              key: 'logout',
              icon: <LogoutOutlined />,
              label: <span onClick={handleLogout}>Logout</span>,
            },
          ]}
          mode="vertical"
        />
      </div>
    </div>
  );
};

export default SideNavbar;
