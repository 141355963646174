import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import UserService from '../../services/user-actions.service';
import { openNotificationWithIcon } from '../../constants/constant';
import styles from  "./styles.module.css"

const CreatePassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [queryParams, setQueryParams] = useState({ token: '', name: '', email: '' });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const name = searchParams.get('name');
    const email = searchParams.get('email');
    if (token && name && email) {
      setQueryParams({ token, name, email });
    } else {
      openNotificationWithIcon('error', 'Error', 'Invalid or missing query parameters');
    }
  }, [location.search]);

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const response = await UserService.verifyEmail(queryParams, {
        newPassword: values.newPassword,
        confirmPassword: values.confirmPassword,
      });
      console.log(response)
      openNotificationWithIcon('success', 'Success', 'Password created successfully');
      navigate('/login');
    } catch (error) {
      openNotificationWithIcon('error', 'Error', 'Failed to create password');
    }
    setIsLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className={styles.login}>
      <div className={styles.form_container}>
        <div className={styles.right}>
          <h1 className={styles.from_heading}>Looker Embed Portal - Create Password</h1>
          <Form
            name="createPassword"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24, style: { marginTop: '-10px' } }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[
                { required: true, message: 'Please input your new password!' },
                { min: 6, message: 'Password must be at least 6 characters' },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              dependencies={['newPassword']}
              rules={[
                { required: true, message: 'Please confirm your new password!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 26 }}>
              <Button type="primary" htmlType="submit" loading={isLoading} >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CreatePassword;
