import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal, Input, Select, Table, notification, Popconfirm } from 'antd';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DashboardService from '../../services/dashboard.service';
import styles from './styles.module.css';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

const { Option } = Select;

const Row = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'],
  });
  const style = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: 'move',
    ...(isDragging ? { position: 'relative' } : {}),
  };
  return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};

const ParentRow = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'],
  });
  const style = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: 'move',
    ...(isDragging ? { position: 'relative' } : {}),
  };
  return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};

const Report = () => {
  const [dashboards, setDashboards] = useState([]);
  const [parents, setParents] = useState([]);
  const [parentSequence, setParentSequence] = useState(false);
  const [childSequence, setChildSequence] = useState(false);
  const [childrenChanged, setChildrenChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newParentName, setNewParentName] = useState('');
  const [selectedDashboards, setSelectedDashboards] = useState([]);

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description,
    });
  };

  const fetchParentData = async () => {
    setLoading2(true);
    try {
      const response = await DashboardService.getParentData();
      setParents(response.data.rows);
    } catch (error) {
      console.error('Failed to fetch parent data:', error);
      notification.error({ message: 'Error', description: 'Failed to fetch parent data' });
    }
    setLoading2(false);
  };
  const initializedParent = useRef(false);
  const initializedChildren = useRef(false);

  useEffect(() => {
    if (!initializedParent.current) {
      initializedParent.current = true;
      fetchParentData();
    }
  }, []);

  const fetchDashboards = async () => {
    setLoading(true);
    const flag = true;
    try {
      const data = await DashboardService.getDashboardData(1, 1000, '', flag);
      setDashboards(data.data.rows.sort((a, b) => a.sequence - b.sequence));
    } catch (error) {
      notification.error({ message: 'Error', description: 'Failed to fetch dashboards' });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!initializedChildren.current) {
      initializedChildren.current = true;
      fetchDashboards();
    }
  }, []);

  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 1 } }));

  const onParentDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setParents((prev) => {
        const activeIndex = prev.findIndex((i) => i.name === active.id);
        const overIndex = prev.findIndex((i) => i.name === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
      setParentSequence(true);
    }
  };

  const onDashboardDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDashboards((prev) => {
        const activeIndex = prev.findIndex((i) => i.id === active.id);
        const overIndex = prev.findIndex((i) => i.id === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
      setChildSequence(true);
    }
  };

  const handleSaveChildren = async () => {
    setLoading2(true);
    try {
      const updatedSequence = dashboards.reduce((acc, dashboard, index) => {
        acc[index + 1] = dashboard.link;
        return acc;
      }, {});

      const requestBody = { sequence: updatedSequence };

      await DashboardService.updateSequence(requestBody);
      notification.success({ message: 'Success', description: 'Dashboard sequence updated successfully' });
      setChildSequence(false);
      setLoading2(false);
    } catch (error) {
      notification.error({ message: 'Error', description: 'Failed to update dashboard sequence' });
    }
  };

  const handleSaveParent = async () => {
    setLoading(true);
    try {
      const parentData = parents.map((parent, index) => ({
        name: parent.name,
        child: parent.child,
        sequence: index + 1,
      }));
  
      await DashboardService.createParent({ data: parentData });
      notification.success({ message: 'Success', description: 'Parent sequence updated successfully' });
      await fetchParentData();
      setParentSequence(false);
      setChildrenChanged(false);
    } catch (error) {
      notification.error({ message: 'Error', description: 'Failed to update parent sequence' });
    } finally {
      setLoading(false);
    }
  };
  
  const handleDelete = async (id) => {
    setLoading(true);
    try {
      if (id) {
        await DashboardService.deleteParent(id);
        fetchParentData();
        openNotificationWithIcon('success', 'Success', 'Parent deleted successfully');
      } else {
        setParents((prev) => prev.filter((parent) => parent.id !== id));
        openNotificationWithIcon('success', 'Success', 'Parent removed successfully');
      }
    } catch (error) {
      console.error('Failed to delete parent:', error);
      notification.error({ message: 'Error', description: 'Failed to delete parent' });
    } finally {
      setLoading(false);
    }
  };
  
  const handleAddParent = async () => {
    setLoading(true);
    const newParent = {
      name: newParentName,
      child: selectedDashboards,
    };
  
    const updatedParents = [...parents.map((parent) => {
      const filteredChildren = parent.child.filter((child) => !selectedDashboards.includes(child));
      return { ...parent, child: filteredChildren };
    }), newParent];
  
    const parentData = updatedParents.map((parent, index) => ({
      name: parent.name,
      child: parent.child,
      sequence: index + 1,
    }));
  
    setParents(updatedParents);
    setParentSequence(true);
    setNewParentName('');
    setSelectedDashboards([]);
    setIsModalVisible(false);
  
    try {
      await DashboardService.createParent({ data: parentData });
      fetchParentData();
      openNotificationWithIcon('success', 'Success', 'Parent created successfully');
    } catch (error) {
      notification.error({ message: 'Error', description: 'Failed to create parent' });
    } finally{
      setLoading(false);
    }
  };
  

  const handleSelectChildren = (parentName, selectedChildren) => {
    setParents((prevParents) => {
      const updatedParents = prevParents.map((parent) => {
        if (parent.name === parentName) {
          return { ...parent, child: selectedChildren };
        }
        const filteredChildren = parent.child.filter(child => !selectedChildren.includes(child));
        return { ...parent, child: filteredChildren };
      });
      return updatedParents;
    });
    setChildrenChanged(true);
  };

  const parentColumns = [
    { title: 'Parent', dataIndex: 'name', key: 'name' },
    {
      title: 'Children',
      dataIndex: 'child',
      key: 'child',
      render: (_, record) => (
        <Select
          mode="multiple"
          value={record.child}
          onChange={(value) => handleSelectChildren(record.name, value)}
          style={{ width: '100%' }}
        >
          {dashboards.map((dashboard) => (
            <Option key={dashboard.id} value={dashboard.link}>
              {dashboard.title}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 100,
      render: (_, record) => (
        <div>
          <Popconfirm
            title="Delete report"
            placement="topRight"
            description="Are you sure to delete this report?"
            onConfirm={() => { handleDelete(record.id); }}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const dashboardColumns = [
    { title: 'Title', dataIndex: 'title', key: 'title' },
    { title: 'Link', dataIndex: 'link', key: 'link' },
  ];

  return (
    <div className={styles.reports}>
      <div className={styles.header}>
        <h1 className={styles.title}>Reports</h1>
        <Button className={styles.createButton} type="primary" onClick={() => setIsModalVisible(true)} loading={loading}>
          <PlusOutlined /> Add Parent
        </Button>
        <Modal
          title="Add Parent"
          open={isModalVisible}
          onOk={handleAddParent}
          onCancel={() => setIsModalVisible(false)}
        >
          <Input
            placeholder="Enter parent name"
            value={newParentName}
            onChange={(e) => setNewParentName(e.target.value)}
          />
          <Select
            mode="multiple"
            placeholder="Select child dashboards"
            value={selectedDashboards}
            onChange={(value) => setSelectedDashboards(value)}
            className={styles.selectChildren}
          >
            {dashboards.map((dashboard) => (
              <Option key={dashboard.id} value={dashboard.link}>
                {dashboard.title}
              </Option>
            ))}
          </Select>
        </Modal>
      </div>
      <div className={styles.section}>
        <DndContext sensors={sensors} onDragEnd={onParentDragEnd}>
          <SortableContext items={parents.map((parent) => parent.name)} strategy={verticalListSortingStrategy}>
            <Table
              components={{ body: { row: ParentRow } }}
              rowKey="name"
              columns={parentColumns}
              dataSource={parents}
              pagination={false}
              loading={loading}
              bordered
            />
          </SortableContext>
        </DndContext>
        <Button
          disabled={!parentSequence && !childrenChanged}
          loading={loading}
          type="primary"
          onClick={handleSaveParent}
          className={styles.saveButton}
        >
          Save parent sequence
        </Button>
      </div>
      <div className={styles.section}>
        <DndContext sensors={sensors} onDragEnd={onDashboardDragEnd}>
          <SortableContext items={dashboards.map((dashboard) => dashboard.id)} strategy={verticalListSortingStrategy}>
            <Table
              components={{ body: { row: Row } }}
              rowKey="id"
              columns={dashboardColumns}
              dataSource={dashboards}
              pagination={false}
              loading={loading2}
              bordered
            />
          </SortableContext>
        </DndContext>
        <Button
          type="primary"
          disabled={!childSequence}
          onClick={handleSaveChildren}
          loading={loading2}
          className={styles.saveButton}
        >
          Save children sequence
        </Button>
      </div>
    </div>
  );
};

export default Report;
