// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.module.css */
.styles_dashboard__Jggho {
    width: 100%;
  }
  
  .styles_header__wPbap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .styles_title__L102I {
    margin: 0;
  }
  
  .styles_createButton__kwynM {
    margin-left: auto;
  }

  .styles_addDashform__159Qt{
    max-width: 600;
  }
  .styles_editDashForm__Wh4HJ{
    max-width: 600;
  }
  .styles_searchInput__Eena-{
    width: 30%;
    height: 2.5rem;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/styles.module.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,WAAW;EACb;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,SAAS;EACX;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,cAAc;EAChB;EACA;IACE,cAAc;EAChB;EACA;IACE,UAAU;IACV,cAAc;EAChB","sourcesContent":["/* styles.module.css */\n.dashboard {\n    width: 100%;\n  }\n  \n  .header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 20px;\n  }\n  \n  .title {\n    margin: 0;\n  }\n  \n  .createButton {\n    margin-left: auto;\n  }\n\n  .addDashform{\n    max-width: 600;\n  }\n  .editDashForm{\n    max-width: 600;\n  }\n  .searchInput{\n    width: 30%;\n    height: 2.5rem;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboard": `styles_dashboard__Jggho`,
	"header": `styles_header__wPbap`,
	"title": `styles_title__L102I`,
	"createButton": `styles_createButton__kwynM`,
	"addDashform": `styles_addDashform__159Qt`,
	"editDashForm": `styles_editDashForm__Wh4HJ`,
	"searchInput": `styles_searchInput__Eena-`
};
export default ___CSS_LOADER_EXPORT___;
