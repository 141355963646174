import axios from '../config/axios.config';

const KeyService = {
  fetchAllKeys: async (page, limit, search) => {
    try {
      const response = await axios.get('/admin/keys', {
        params: {page, limit, search},
      });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch keys:', error);
      throw error;
    }
  },

  createKey: async (data) => {
    try {
      const response = await axios.post('/admin/key', data);
      return response.data;
    } catch (error) {
      console.error('Failed to create key:', error);
      throw error;
    }
  },

  deleteKey: async (id) => {
    try {
      const response = await axios.delete(`/admin/key/${id}`);
      return response.data;
    } catch (error) {
      console.error('Failed to delete key:', error);
      throw error;
    }
  },

  revokeKey: async (id) => {
    try {
      const response = await axios.put(`/admin/key-revoke/${id}`);
      return response.data;
    } catch (error) {
      console.error('Failed to revoke key:', error);
      throw error;
    }
  },
};


export default KeyService;
