import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import styles from "./styles.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import UserService from "../../services/user-actions.service";
import { openNotificationWithIcon } from "../../constants/constant";

function ResetPassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  console.log("Tpken", token);

  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    if (values.password !== values.confirmPassword) {
      openNotificationWithIcon(
        "info",
        "Info",
        "Password and Confirm Password should be same"
      );
      return;
    }
    setLoading(true);
    try {
      await UserService.resetPassword(values, token);

      openNotificationWithIcon(
        "success",
        "Success",
        "Password reset successfully"
      );
	  navigate("/login")

    } catch (error) {
      if (error.response && error.response.status === 404) {
        openNotificationWithIcon("error", "Error", "User not found");
      } else {
        console.error(error);
        openNotificationWithIcon(
          "error",
          "Error",
          "An error occurred while resetting the password"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className={styles.login}>
      <div className={styles.form_container}>
        <div className={styles.right}>
          <h2 className={styles.from_heading}>Reset Password</h2>

          <Form
            name="basic"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24, style: { marginTop: "-10px" } }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 5, span: 26 }}>
              <Button type="primary" htmlType="submit" loading={loading}>
                Reset Password
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
