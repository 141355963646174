// axios.config.js
import axios from "axios";
import config from "./app.config";

const instance = axios.create({
    baseURL: config.baseApiUrl,
    headers: {
        'Content-Type': 'application/json',
    },
});


instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


instance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        let res = error.response;
        console.log('error', res);
        return Promise.reject(error);
    }
);

export default instance;
