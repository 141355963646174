import React, { useEffect, useState, useRef } from 'react';
import { Table, Alert, Switch, Button, Input, Modal, Form, Popconfirm, notification } from 'antd';
import DashboardService from '../../services/dashboard.service';
import styles from './styles.module.css';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

function Dashboard() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [createDashboardModal, setCreateDashboardModal] = useState(false);
  const [editDashboardModal, setEditDashboardModal] = useState(false);
  const [createDashboardForm] = Form.useForm();
  const [editDashboardForm] = Form.useForm();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
  const [search, setSearch] = useState("");

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description,
    });
  };
  const initialized = useRef(false)


  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      fetchDashboardData(pagination.current, pagination.pageSize);
    }
  }, []);



  const fetchDashboardData = async (page, pageSize, search) => {
    setLoading(true);
    try {
      const result = await DashboardService.getDashboardData(page, pageSize, search);
      setData(result.data.rows);
      setFilteredData(result.data.rows);
      setPagination(prevState => ({
        ...prevState,
        current: page,
        pageSize: pageSize,
        total: result.data.count,
      }));
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination) => {
    fetchDashboardData(pagination.current, pagination.pageSize, search);
  };

  const handleSearch = (event) => {
    try {
      if (event.key === 'Enter') {
        const searchValue = event.target.value;
        setSearch(searchValue);
        fetchDashboardData(pagination.current, pagination.pageSize, searchValue);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await DashboardService.deleteDashboard(id);
      await fetchDashboardData(pagination.current, pagination.pageSize, search);
      openNotificationWithIcon('success', 'Success', 'Dashboard deleted successfully');
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const createDashboard = async (values) => {
    setLoading(true);
    try {
      const response = await DashboardService.createDashboard(values);
      await fetchDashboardData(pagination.current, pagination.pageSize, search);
      if(response){
        openNotificationWithIcon('success', 'Success', 'Dashboard created successfully');
        closeCreateDashboardModal();
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const updateDashboard = async (values) => {
    const updatedValues = {
      title: values.title,
      link: values.link
    };
    setLoading(true);
    try {
      const response = await DashboardService.updateDashboard(values.id, updatedValues);
      await fetchDashboardData(pagination.current, pagination.pageSize, search);
      if(response){
        openNotificationWithIcon('success', 'Success', 'Dashboard updated successfully');
        closeEditModal();
      }
    } catch (error) {
      setError(error);
      openNotificationWithIcon('error', 'Error', 'Failed to update dashboard');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1;
    setLoading(true);
    try {
      await DashboardService.updateStatus(id, newStatus);
      await fetchDashboardData(pagination.current, pagination.pageSize, search);
      openNotificationWithIcon('success', 'Success', 'Status updated successfully');
    } catch (error) {
      setError(error);
      openNotificationWithIcon('error', 'Error', 'Failed to update status');
    } finally {
      setLoading(false);
    }
  };

  // const handleSearch = (event) => {
  //   const value = event.target.value.toLowerCase();
  //   const filtered = data.filter((item) =>
  //     item.title.toLowerCase().includes(value) ||
  //     item.link.toLowerCase().includes(value)
  //   );
  //   setFilteredData(filtered);
  // };

  const openCreateDashboardModal = () => {
    setCreateDashboardModal(true);
  };

  const closeCreateDashboardModal = () => {
    setCreateDashboardModal(false);
    createDashboardForm.resetFields();
  };

  const openEditDashboardModal = (dashboard) => {
    setEditDashboardModal(true);
    editDashboardForm.setFieldsValue({
      title: dashboard.title,
      link: dashboard.link,
      id: dashboard.id,
    });
  };

  const closeEditModal = () => {
    setEditDashboardModal(false);
    editDashboardForm.resetFields();
  };



  const columns = [
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title',
      width: '20%'
    },
    {
      title: 'Link',
      dataIndex: 'link',
      key: 'link'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <Switch
          checked={record.status}
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          onChange={() => handleStatusChange(record.id, status)}
        />
      ),
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => dayjs(created_at).format('MMMM D, YYYY'),
    },
    {
      title: 'Updated at',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (updated_at) => dayjs(updated_at).format('MMMM D, YYYY'),
    },
    {
      title: 'Created by',
      dataIndex: 'created_by',
      key: 'created_by',
    },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: 120,
      render: (text, record) => (
        <div style={{ display: 'flex', gap: '2px' }}>
          <Button onClick={() => openEditDashboardModal(record)}>
            <EditOutlined />
          </Button>
          <Popconfirm
            title="Delete dashboard"
            placement="topRight"
            description="Are you sure to delete this dashboard?"
            onConfirm={() => handleDelete(record.id)}
            onCancel={() => { }}
            okText="Yes"
            cancelText="No"
          >
            <Button danger><DeleteOutlined /></Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  if (error) {
    return <Alert message="Error" description={error.message} type="error" showIcon />;
  }

  return (
    <div className={styles.dashboard}>
      <div className={styles.header}>
        <h1 className={styles.title}>Dashboards</h1>
        <Button type="primary" className={styles.createButton} onClick={openCreateDashboardModal}><PlusOutlined /> Create</Button>
        <Modal
          form={createDashboardForm}
          footer={[
            <Button key="back" onClick={closeCreateDashboardModal}>
              Back
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={createDashboardForm.submit}>
              Submit
            </Button>,
          ]}
          title="Create dashboard" open={createDashboardModal} onCancel={closeCreateDashboardModal}
        >
          <Form
            form={createDashboardForm}
            name="createDashboard"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24, style: { marginTop: '-10px' } }}
            className={styles.addDashform}
            onFinish={createDashboard}
            autoComplete="off"
          >
            <Form.Item
              label="Title"
              name="title"
              rules={[{ required: true, message: 'Please input your title!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Link"
              name="link"
              rules={[{ required: true, message: 'Please input your link!' }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          form={editDashboardForm}
          footer={[
            <Button key="back" onClick={closeEditModal}>
              Back
            </Button>,
            <Button key="submit" type="primary" onClick={editDashboardForm.submit} loading={loading}>
              Submit
            </Button>,
          ]}
          title="Edit dashboard" open={editDashboardModal} onCancel={closeEditModal}
        >
          <Form
            form={editDashboardForm}
            name="editDashboard"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24, style: { marginTop: '-10px' } }}
            className={styles.editDashForm}
            onFinish={updateDashboard}
            autoComplete="off"
          >
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            <Form.Item
              label="Title"
              name="title"
              rules={[{ required: true, message: 'Please input your title!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Link"
              name="link"
              rules={[{ required: true, message: 'Please input your link!' }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </div>
      <Input className={styles.searchInput}         onKeyDown={handleSearch}

 placeholder="Search dashboard" />
      <div style={{ marginTop: '2rem' }}>
        <Table
          loading={loading}
          scroll={{ x: 1300 }}
          bordered
          dataSource={filteredData}
          columns={columns}
          rowKey={(record) => record.id}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
          }}
          onChange={handleTableChange}
        />
      </div>
    </div>
  );
}

export default Dashboard;
