import React, { useEffect, useState, useRef } from "react";
import { Alert, Button, Input, notification, Form, Modal } from "antd";
import KeyService from "../../services/key.service";
import styles from "./styles.module.css";
import { CopyOutlined, EyeTwoTone, EyeInvisibleOutlined, PlusOutlined } from "@ant-design/icons";

function ApiKeys() {
  const [apiKey, setApiKey] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const initialized = useRef(false);

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description,
    });
  };

  const onFinish = async () => {
    setLoading(true);

    await new Promise((resolve) => setTimeout(resolve, 2000));

    try {
      const result = await KeyService.createKey();
      if (result?.data) {
        setApiKey(result.data);
        openNotificationWithIcon("success", "Success", "API Key generated successfully");
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  const copyToClipboard = () => {
    navigator.clipboard.writeText(apiKey);
    openNotificationWithIcon("success", "Copied", "API Key copied to clipboard");
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      const fetchKeyData = async () => {
        setLoading(true);
        try {
          const result = await KeyService.fetchAllKeys();
          if (result?.data) {
            setApiKey(result.data);
          } else {
            setApiKey("");
            openNotificationWithIcon("info", "No Keys", "No API keys found");
          }
        } catch (error) {
          setError(error);
        } finally {
          setLoading(false);
        }
      };
      fetchKeyData();
    }

  }, []);

  if (error) {
    return (
      <Alert
        message="Error"
        description={error.message}
        type="error"
        showIcon
      />
    );
  }

  return (
    <div className={styles.dashboard}>
      <div className={styles.header}>
        <h1 className={styles.title}>API Key</h1>
      </div>

      <div className={styles.apiKeyContainer}>
        <Input.Password
          value={apiKey}
          readOnly
          iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
        />
        <div className={styles.btnContainer}>
          <Button type="primary" icon={<CopyOutlined />} onClick={copyToClipboard} loading={loading}
            className={styles.createButton}
          >
            Copy
          </Button>

          <Button
            type="primary"
            className={styles.createButton}
            onClick={onFinish}
            loading={loading}

          >
            <PlusOutlined /> Generate
          </Button>
        </div>

      </div>

    </div>
  );
}

export default ApiKeys;
