// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_reports__b2GTN {
  width: 100%;
}

.styles_header__gMESt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.styles_title__1ggoM {
  margin: 0;
}

.styles_createButton__La-LU {
  margin-left: auto;
}
.styles_section__HWGrE{
  margin-bottom: 3rem;
}
.styles_selectChildren__Hc\\+uD{
  width: 100%;
   margin-top: 1rem
}

.styles_saveButton__Z8ptO{
  margin-top: 1rem; 
}
`, "",{"version":3,"sources":["webpack://./src/pages/Report/styles.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,iBAAiB;AACnB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,WAAW;GACV;AACH;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".reports {\n  width: 100%;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 20px;\n}\n\n.title {\n  margin: 0;\n}\n\n.createButton {\n  margin-left: auto;\n}\n.section{\n  margin-bottom: 3rem;\n}\n.selectChildren{\n  width: 100%;\n   margin-top: 1rem\n}\n\n.saveButton{\n  margin-top: 1rem; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reports": `styles_reports__b2GTN`,
	"header": `styles_header__gMESt`,
	"title": `styles_title__1ggoM`,
	"createButton": `styles_createButton__La-LU`,
	"section": `styles_section__HWGrE`,
	"selectChildren": `styles_selectChildren__Hc+uD`,
	"saveButton": `styles_saveButton__Z8ptO`
};
export default ___CSS_LOADER_EXPORT___;
