// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.module.css */
.styles_dashboard__\\+0ywh {
    width: 100%;
  }
  
  .styles_header__NoSPg {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .styles_title__J0wF4 {
    margin: 0;
  }
  
  .styles_createButton__FMPRE {
    margin-left: auto;
  }
  .styles_searchInput__tvE-b{
    width: 30%;
    height: 2.5rem;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/User/styles.module.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,WAAW;EACb;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,SAAS;EACX;;EAEA;IACE,iBAAiB;EACnB;EACA;IACE,UAAU;IACV,cAAc;EAChB","sourcesContent":["/* styles.module.css */\n.dashboard {\n    width: 100%;\n  }\n  \n  .header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 20px;\n  }\n  \n  .title {\n    margin: 0;\n  }\n  \n  .createButton {\n    margin-left: auto;\n  }\n  .searchInput{\n    width: 30%;\n    height: 2.5rem;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboard": `styles_dashboard__+0ywh`,
	"header": `styles_header__NoSPg`,
	"title": `styles_title__J0wF4`,
	"createButton": `styles_createButton__FMPRE`,
	"searchInput": `styles_searchInput__tvE-b`
};
export default ___CSS_LOADER_EXPORT___;
