// Login.jsx
import { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Input } from "antd";
import UserService from "../../services/user-actions.service";
import styles from "./styles.module.css";
import { openNotificationWithIcon } from '../../constants/constant';

function Login() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const response = await UserService.login(values);

      const token = await response.data.data.accessToken;
      const userData = await response.data.data.userInfo;

      await localStorage.setItem("token", token);
      await localStorage.setItem("userName", userData.name);
      await localStorage.setItem("admin", userData.is_admin);

      navigate("/dashboard");
    } catch (error) {
      if (error.response && error.response.status === 404) {
        openNotificationWithIcon("error", "Error", "User not found");
      }
      if (error.response && error.response.status === 401) {
        openNotificationWithIcon("error", "Error", "Invalid credentials");
      }
      if (error.response && error.response.status === 400) {
        openNotificationWithIcon("error", "Error", "User not verified");
      }
      console.error("Login failed:", error);
    }
    setIsLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className={styles.login}>
      <div className={styles.form_container}>
        <div className={styles.right}>
          <h1 className={styles.from_heading}>Looker Embed Portal - Login</h1>
          <Form
            name="basic"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24, style: { marginTop: "-10px" } }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 26 }}>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Log In
              </Button>
            </Form.Item>
            <div style={{ textAlign: "center" }}>
              <Link to="/forget-password">Forgot password ?</Link>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Login;
