import React, { useEffect, useState,useRef } from "react";
import {
  Table,
  Alert,
  Button,
  Input,
  Modal,
  Form,
  Popconfirm,
  notification,
  Tag,
  Select,
} from "antd";
import UserService from "../../services/user-actions.service";
import styles from "./styles.module.css";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const { Option } = Select;

function User() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [search, setSearch] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [admin, setAdmin] = useState(false);


  useEffect(() => {
    const isAdmin = JSON.parse(localStorage.getItem("admin")) || false;
    setAdmin(isAdmin);
  }, []);


  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description,
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const { role, ...rest } = values;
      const payload = {
        ...rest,
        isAdmin: role === 'admin',
      };
      const response = await UserService.inviteUser(payload);
      fetchUserData(pagination.current, pagination.pageSize, search);
      if(response){
        openNotificationWithIcon("success", "Success", "User Invited");
        handleCancel();
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserData = async (page, pageSize, search) => {
    setLoading(true);
    try {
      const result = await UserService.fetchAllUsers(page, pageSize, search);
      setData(result.data.rows);
      setPagination({
        current: page,
        pageSize: pageSize,
        total: result.data.count,
      });
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const initialized = useRef(false)


  useEffect(() => {
    if(!initialized.current) {
      initialized.current = true;
      fetchUserData(pagination.current, pagination.pageSize);
    }
  }, []);

  const handleTableChange = (pagination) => {
    fetchUserData(pagination.current, pagination.pageSize, search);
  };

  const handleSearch = (event) => {
    try {
      if (event.key === 'Enter') {
        const searchValue = event.target.value;
        setSearch(searchValue);
        fetchUserData(pagination.current, pagination.pageSize, searchValue);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await UserService.deleteUser(id);
      fetchUserData(pagination.current, pagination.pageSize, search);
      openNotificationWithIcon(
        "success",
        "Success",
        "User deleted successfully"
      );
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const showEditModal = (record) => {
    setEditModalOpen(true);
  };

  const handleEditCancel = () => {
    setEditModalOpen(false);
    form.resetFields();
  };

  const onEditFinish = async (values) => {
    console.log("Finish Triggered")
    setLoading(true);
    try {
      const response = await UserService.updatePassword(values);
      fetchUserData(pagination.current, pagination.pageSize, search);
      if(response){
        openNotificationWithIcon("success", "Success", "Password updated successfully");
        handleEditCancel();
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "is_verified",
      key: "is_verified",
      width: "20%",
      render: (is_verified, record) =>
        is_verified ? (
          <Tag color="green">VERIFIED</Tag>
        ) : (
          <Tag color="red">PENDING</Tag>
        ),
    },
    {
      title: "Role",
      dataIndex: "is_admin",
      key: "is_admin",
      width: "12%",
      render: (is_admin, record) =>
        is_admin ? (
          <Tag color="green">ADMIN</Tag>
        ) : (
          <Tag color="red">STAFF</Tag>
        ),
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => dayjs(created_at).format("MMMM D, YYYY"),
    },
    {
      title: "Updated at",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (updated_at) => dayjs(updated_at).format("MMMM D, YYYY"),
    },
  ];
  if (admin) {
    columns.push({
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 140,
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
          <Popconfirm
            title="Are you sure you want to delete this user?"
            placement="topRight"
            onConfirm={() => handleDelete(record.id)}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
          <Button onClick={() => showEditModal(record)}>
            <EditOutlined />
          </Button>
        </div>
      ),
    });
  }

  if (error) {
    return (
      <Alert
        message="Error"
        description={error.message}
        type="error"
        showIcon
      />
    );
  }

  return (
    <div className={styles.dashboard}>
      <div className={styles.header}>
        <h1 className={styles.title}>User</h1>
        <Button
          type="primary"
          className={styles.createButton}
          onClick={showModal}
        >
          <PlusOutlined /> Invite
        </Button>
        <Modal
          form={form}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Back
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={form.submit}
            >
              Submit
            </Button>,
          ]}
          title="Invite User"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form
            form={form}
            name="basic"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
              style: { marginTop: "-10px" },
            }}
            style={{
              maxWidth: 600,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter the name of the user!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter the email of the user!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Role"
              name="role"
              rules={[
                {
                  required: true,
                  message: "Please select the role of the user!",
                },
              ]}
            >
              <Select placeholder="Select a role">
                <Option value="admin">Admin</Option>
                <Option value="staff">Staff</Option>
              </Select>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Change Password"
          open={editModalOpen}
          onCancel={handleEditCancel}
          footer={[
            <Button key="back" onClick={handleEditCancel}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={form.submit}>
              Submit
            </Button>,
          ]}
        >
          <Form
            form={form}
            name="edit"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24, style: { marginTop: "-10px" } }}
            onFinish={onEditFinish}
            initialValues={{ newPassword: '', confirmPassword: '' }}
          >
            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: "Please enter the new password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: "Please confirm the new password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Form>
        </Modal>
      </div>
      <Input
        className={styles.searchInput}
        placeholder="Search Users"
        onKeyDown={handleSearch}
      />
      <div style={{ marginTop: "2rem" }}>
        <Table
          loading={loading}
          scroll={{
            x: 1300,
          }}
          bordered
          dataSource={data}
          columns={columns}
          rowKey={(record) => record.id}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
          }}
          onChange={handleTableChange}
        />
      </div>
    </div>
  );
}

export default User;
