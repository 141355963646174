// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.siebar_sidebar__kCI-s {
  display: flex;
  flex-direction: column;
  border-right: 0.5px solid;
  height: 100vh;
  width: 12vw;
}

.siebar_userProfile__kdkzg {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10%;
}

.siebar_userName__Cg3NA {
  margin-top: 10px;
  text-align: center;
}

.siebar_menu__T0nmL {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.siebar_logoutContainer__-oLZh {
  margin-top: auto;
  padding: 10px;
  border-top: 0.5px solid;
}`, "",{"version":3,"sources":["webpack://./src/components/SideNavbar/siebar.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,aAAa;EACb,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".sidebar {\n  display: flex;\n  flex-direction: column;\n  border-right: 0.5px solid;\n  height: 100vh;\n  width: 12vw;\n}\n\n.userProfile {\n  margin-top: 10px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 10%;\n}\n\n.userName {\n  margin-top: 10px;\n  text-align: center;\n}\n\n.menu {\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n  overflow-y: auto;\n}\n\n.logoutContainer {\n  margin-top: auto;\n  padding: 10px;\n  border-top: 0.5px solid;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `siebar_sidebar__kCI-s`,
	"userProfile": `siebar_userProfile__kdkzg`,
	"userName": `siebar_userName__Cg3NA`,
	"menu": `siebar_menu__T0nmL`,
	"logoutContainer": `siebar_logoutContainer__-oLZh`
};
export default ___CSS_LOADER_EXPORT___;
