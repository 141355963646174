import { Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Forgetpassword from './pages/ForgotPassword'
import Reset from './pages/ResetPassword'
import User from "./pages/User";
import ApiKeys from "./pages/ApiKeys";
import Report from "./pages/Report";
import ContentWrapper from "./components/Wrapper/ContentWrapper";
import CreatePassword from "./pages/CreatePassword";
import "./App.css";

function App() {
  return (
    <div className="container">
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/dashboard" element={<ContentWrapper><Dashboard /></ContentWrapper>} />
        <Route exact path="/apiKeys" element={<ContentWrapper><ApiKeys /></ContentWrapper>} />
        <Route exact path="/user" element={<ContentWrapper><User /></ContentWrapper>} />
        <Route exact path="/report" element={<ContentWrapper><Report /></ContentWrapper>} />
        <Route exact path="/login" element={<Login />} />
        <Route path="/invite-user" element={<CreatePassword />} />
        <Route path="/forget-password" element={<Forgetpassword />} />
        <Route path="/reset-password" element={<Reset />} />
      </Routes>
    </div>
  );
}

export default App;
