// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.module.css */
.styles_dashboard__KRBE- {
  width: 100%;
}

.styles_btnContainer__TBjAI {
  display: flex;
  gap: 1rem
}

.styles_apiKeyContainer__mXT\\+G {
  margin-top: 5rem;
  width: 60%;
  height: 50%;
  padding: 2rem;
  border-radius: 0.375rem;
  background-color: rgb(221, 236, 236);
}

.styles_header__5JFJo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.styles_title__86dDG {
  margin: 0;
}

.styles_createButton__nR994 {
  margin-top: 2rem;
}

.styles_apiForm__9IBU7 {
  max-width: 600;
}

.styles_searchInput__JHyFp {
  width: 30%;
  height: 2.5rem
}`, "",{"version":3,"sources":["webpack://./src/pages/ApiKeys/styles.module.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb;AACF;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,UAAU;EACV;AACF","sourcesContent":["/* styles.module.css */\n.dashboard {\n  width: 100%;\n}\n\n.btnContainer {\n  display: flex;\n  gap: 1rem\n}\n\n.apiKeyContainer {\n  margin-top: 5rem;\n  width: 60%;\n  height: 50%;\n  padding: 2rem;\n  border-radius: 0.375rem;\n  background-color: rgb(221, 236, 236);\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 20px;\n}\n\n.title {\n  margin: 0;\n}\n\n.createButton {\n  margin-top: 2rem;\n}\n\n.apiForm {\n  max-width: 600;\n}\n\n.searchInput {\n  width: 30%;\n  height: 2.5rem\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboard": `styles_dashboard__KRBE-`,
	"btnContainer": `styles_btnContainer__TBjAI`,
	"apiKeyContainer": `styles_apiKeyContainer__mXT+G`,
	"header": `styles_header__5JFJo`,
	"title": `styles_title__86dDG`,
	"createButton": `styles_createButton__nR994`,
	"apiForm": `styles_apiForm__9IBU7`,
	"searchInput": `styles_searchInput__JHyFp`
};
export default ___CSS_LOADER_EXPORT___;
