import axios from '../config/axios.config';
import { openNotificationWithIcon } from '../constants/constant';

const DashboardService = {
  getDashboardData: async (page = 1, limit = 10, search, flag = false) => {
    try {
      const response = await axios.get('/admin/dashboards', {
        params: { page, limit, search, flag },
      });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch dashboard data:', error);
      throw error;
    }
  },
  getParentData: async () => {
    try {
      const response = await axios.get('/admin/parents', {

      });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch dashboard data:', error);
      throw error;
    }
  },
  createDashboard: async (data) => {
    try {
      const response = await axios.post('/admin/dashboard', data);
      return response.data;
    } catch (error) {
      if (error.response.status === 409) {
        openNotificationWithIcon('error', 'Error', 'Dashboard with link already exists');
      }
      else { 
        openNotificationWithIcon('error', 'Error', 'Failed to create dashboard'); 
      }
    }
  },
  createParent: async (data) => {
    try {
      const response = await axios.post('/admin/parent', data);
      return response.data;
    } catch (error) {
      console.error('Failed to create dashboard:', error);
      throw error;
    }
  },

  updateDashboard: async (id, data) => {
    try {
      const response = await axios.put(`/admin/dashboard/${id}`, data);
      return response.data;
    } catch (error) {
      if (error.response.status === 409) {
        openNotificationWithIcon('error', 'Error', 'Dashboard with link already exists');
      }
      else { 
        openNotificationWithIcon('error', 'Error', 'Failed to update dashboard'); 
      }
    }
  },

  updateStatus: async (id, data) => {
    try {
      const response = await axios.put(`/admin/dashboard-status/${id}`, { status: data });
      return response.data;
    } catch (error) {
      console.error('Failed to update status:', error);
      throw error;
    }
  },

  updateSequence: async (data) => {
    try {
      const response = await axios.put(`/admin/dashboard-sequence`, data);
      return response.data;
    } catch (error) {
      console.error('Failed to update status:', error);
      throw error;
    }
  },

  deleteDashboard: async (id) => {
    try {
      const response = await axios.delete(`/admin/dashboard/${id}`);
      return response.data;
    } catch (error) {
      console.error('Failed to delete dashboard:', error);
      throw error;
    }
  },

  deleteParent: async (id) => {
    console.log(id)
    try {
      const response = await axios.delete(`/admin/parent/${id}`);
      return response.data;
    } catch (error) {
      console.error('Failed to delete dashboard:', error);
      throw error;
    }
  },

};



export default DashboardService;
